.communicationSelector {
  text-align: left;
  border-bottom: 2px solid #e5e8ee;
  .ant-select-selector {
    padding: 0 !important;
  }
  .ant-select-arrow {
    right: 0px;
    span {
      color: #3e3e3e !important;
      font-size: 16px !important;
    }
  }
}

.communicationInputNumber {
  border: none !important;
  border-bottom: 1px solid #d1d5db !important;
  input {
    height: auto;
    padding: 8px 10px !important;
    background-color: #f4f4f4;
  }
}

.greyTextBtn {
  span {
    color: #979797;
    text-decoration: none;
  }
}

// communcation placeholder modal start
.communicationPlaceholderModal {
  .ant-modal-body {
    padding-top: 16px !important;
  }
  .ant-modal-title {
    font-family: tradeGothic !important;
    font-size: 22px !important;
  }
}

.communicationsPlaceholderItem {
  display: flex;
  padding: 10px 16px;
  border-bottom: 1px solid #d4dbe9;
  & > :first-child {
    width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
    font-weight: 700;
    box-sizing: border-box;
    font-size: 15px;
  }
  & > :last-child {
    width: calc(100% - 230px);
    font-size: 15px;
  }
}
