// IntakeForms.js Styles Start
.intakeFormsList {
  .ant-list-item {
    padding: 0;
    margin-bottom: 16px;
    border-bottom: none;
  }
  &.minimal {
    .ant-list-item:not(:last-child) {
      margin-bottom: 12px;
    }
    .ant-list-item:last-child {
      margin-bottom: 0px;
    }
  }
}
// IntakeForms.js Styles End

// IntakeFormCard.js Styles Start
.intakeFormCardContainer {
  .informFormCardControls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -16px;
  }
}

.intakeFormCardContainer:not(.minimal) {
  padding-bottom: 16px;
  border-bottom: 1px solid #cddfd5;
  // border-bottom: 3px solid green !important;
}
// IntakeFormCard.js Styles End

// intakeFormReminderSection start
@media (max-width: 767px) {
  .intakeFormReminderSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .reminderBtn {
      margin-left: -15px;
    }
  }
}

// intakeFormReminderSection end

// AuditListItem Start
.auditListItem {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.auditListItemLeft {
  display: flex;
  flex-direction: column;
}
// AuditListItem End
// intakeForm Modal start
.intakeForm {
  .ant-modal-footer {
    padding-top: 12px !important;
    padding-bottom: 18px !important;
  }
}
.intakeForm {
  ul,
  ol {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    margin-bottom: 6px;
    list-style-type: none;

    li {
      margin-left: 12px;
      font-family: inherit;
      font-size: inherit;
      color: inherit;
      line-height: inherit;
      margin-bottom: 6px;
    }
  }

  span.ant-typography {
    color: #000;
    font-family: "tradeGothic";
    span {
      display: block;
      margin-bottom: 6px;
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }
    span:last-child {
      margin-bottom: 0;
    }
  }

  ul {
  }

  .flatFormItemCheckbox {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.boldRadio {
  font-weight: 700 !important;
  * {
    font-weight: 700 !important;
  }
}

// intakeForm Modal end
