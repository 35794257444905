.normalLabel {
  label {
    font-weight: 400 !important;
  }
  span {
    // font-weight: 400;
    font-weight: 400 !important;
  }
}

.label-d-none {
  .ant-form-item-label {
    display: none !important;
  }
  label {
    padding: 0px !important;
  }
}

.templateLabel {
  * {
    font-family: tradeGothic !important;
  }
}

.label-d-none {
  .ant-form-item-label {
    display: none !important;
  }
  label {
    padding: 0px !important;
  }
}

.flatFormItem,
.flat {
  .ant-form-item-label {
    padding-bottom: 6px;
    span {
      line-height: 1;
      font-size: 1rem !important;
      padding-left: 0px;
      font-family: tradeGothic !important;
      font-weight: 700;
    }
    label {
      line-height: 1;
      font-size: 1rem !important;
      padding-left: 0px;
      font-family: tradeGothic !important;
      font-weight: 700;
      * {
        font-family: tradeGothic !important;
      }
    }
  }
  label {
    font-size: 15px !important;
    // font-weight: bold !important;
    padding-left: 10px;
    padding-top: 8px;
    color: black !important;
    font-family: "hendersonSemiBold" !important;
  }
  // for the input fields with addOns
  .ant-input-group-wrapper {
    padding: 0px !important;
    input {
      padding: 12px;
    }
  }
  // outlined fields
  &.outlined {
    .ant-input {
      border: 2px solid #d8dce4 !important;
      border-radius: 6px !important;
    }
  }
  // underlined fields
  &.underlined {
    .ant-input-number-disabled {
      input {
        background-color: #d7d7d7 !important;
      }
    }
    .ant-input-affix-wrapper {
      background-color: #f4f4f4 !important;
      border: 0px !important;
      border-radius: none !important;
      border-bottom: 1px solid #d1d5db !important;
    }
    input,
    textarea {
      background-color: #f4f4f4 !important;
      border: 0px;
      border-bottom: 1px solid #d1d5db;
    }
    .ant-input-password {
      background-color: #f4f4f4 !important;
      border: 0px;
      border-bottom: 1px solid #d1d5db;
      input {
        border: none;
      }
    }
    &.select {
      .ant-select-selection-item {
        font-size: 14px !important;
        line-height: 1;
        // position: absolute;
        // top: 5px;
      }

      .ant-select-selection-placeholder {
        font-size: 14px !important;
        color: #a8acb3 !important;
        line-height: 1;

        // position: absolute;
      }

      .ant-form-item-control-input {
        background-color: #f4f4f4 !important;
        border: 0px;
        min-height: 47px;
        border-bottom: 1px solid #d1d5db;
      }

      .ant-select-selector {
        padding-top: 10px;
        background-color: transparent !important;
        // border: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }

    .flag-dropdown {
      border-left: 0px;
      border-top: 0px;
      border-right: 0px;
    }
  }
  &.filledDatePicker {
    .ant-picker {
      width: 100% !important;
      border: 1px solid #cacaca;
      // border: none !important;
      // border-bottom: 1px solid #d1d5db !important;
      height: 48px !important;
      box-sizing: border-box !important;
    }
  }
  &.underlinedDatePicker {
    .ant-picker {
      width: 100% !important;
      border: none !important;
      border-bottom: 1px solid #d1d5db !important;
      height: 48px !important;
      background-color: #f4f4f4 !important;
      box-sizing: border-box !important;
    }
    &.ant-form-item-has-error {
      .ant-picker {
        border-bottom: 1px solid red !important;
      }
    }
  }

  .react-tel-input {
    height: 47px;
    width: 100%;
    input {
      height: 100%;
      width: 100%;
    }
  }

  & .ant-input-group-addon {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-color: #f4f4f4;
  }
  &:not(.ant-form-item-has-error) .ant-input-group-addon {
    background-color: #f4f4f4;
    border: 0px;
    border-bottom: 1px solid #d1d5db;
  }
}
.select.filled {
  .ant-form-item-control-input {
    border: 0px;
    min-height: 47px;
    border: 1px solid #d9d9d9;
  }
}
.select.searchField {
  outline: none !important;
  width: 100%;

  .ant-select-selection-search-input {
    height: 48px !important;
  }

  div.ant-select-selector {
    border: 2px solid #d8dce4 !important;
    border-radius: 8px !important;
    padding-top: 10px;
    height: 50px !important;
    padding-top: 10px !important;
    outline: none !important;
  }
  .ant-select,
  .ant-select-item {
    font-size: 16px !important;
  }
}

.flatFormItemRadioGroup {
  &.vertical {
    .ant-radio-group {
      display: block;
      .ant-radio-wrapper {
        display: flex;
      }
    }
  }
}

.flatFormItemCheckboxGroup {
  // display: flex;
  // align-items: center;
  // margin-bottom: 0px;
  &.vertical {
    .ant-checkbox-group {
      display: block;
      .ant-checkbox-wrapper {
        display: flex;
      }
    }
  }
  .ant-checkbox-wrapper {
    & > :nth-child(2) {
      align-self: center;
    }
  }
}

.flatFormItemCheckbox {
  display: flex;
  align-items: center;
  // margin-bottom: 0px;
  .ant-checkbox-wrapper {
    & > :nth-child(2) {
      align-self: center;
    }
  }
}
.multiSelect {
  .ant-select-selection-item {
    position: initial !important;
  }
  .ant-select-selection-placeholder {
    font-size: 14px !important;
    color: #a8acb3 !important;
    position: absolute;
    top: 15px !important;
  }
  .ant-select-selection-overflow {
    // background-color: aqua !important;
    position: initial !important;
  }
}

// .locationSelectorContainer {
//   position: relative;
//   .locationSelectorIcon {
//     position: absolute;
//   }
// }
.multiLocationSelector {
  overflow: hidden;
  position: relative;
  div.ant-select-selector {
    padding-left: 5px !important;
    padding-right: 10px !important;
  }
  .ant-select-selection-overflow {
    flex-wrap: nowrap !important;
  }
  .ant-select-selection-overflow-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ant-select-selection-overflow-item-suffix {
    // display: none;
  }
  .ant-select-selection-overflow-item-rest {
    position: absolute;
    right: -2px;
    width: 32px;
    padding: 0px;
    margin: 0px;
    text-overflow: clip;

    .ant-select-selection-item {
      background: #496baf;
      color: #fff;
      border-radius: 5px;
      padding: 0px 0px;
      padding-left: 6px;
      margin: 0px;
      text-overflow: clip;

      .ant-select-selection-item-content {
        font-size: 10px !important;
        text-overflow: clip;
      }
      .ant-select-selection-item-content {
        text-overflow: clip;
      }
    }
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

.locationSearch {
  ::placeholder {
    color: #8c8c8c;
    font-size: 14px !important;
  }
  input {
    font-size: 14px !important;
    padding: 12px 11px;
    border-radius: 0px;
  }
}

.ant-select-selection-placeholder {
  z-index: 1;
}

.flatFormItem.readOnly {
  cursor: default;
  & * {
    cursor: default;
  }
}

.ant-form-item-control {
  .anticon-loading {
    color: blue;
  }
}

*::placeholder {
  color: #bfbfbf !important;
}

.underlinedInput {
  outline: none !important;
  padding: 11px 12px !important;
  background-color: #f4f4f4 !important;
  border: none !important;
  border-bottom: 2px solid #d1d5db !important;
  font-size: 15px !important;
  input {
    // font-family: 'tradeGothic';
    font-size: 15px !important;
    background: transparent !important;
    line-height: 1;
  }
}

.underlinedDatepicker {
  outline: none !important;

  padding: 11px 12px !important;
  background-color: #f4f4f4 !important;
  border: none !important;
  border-bottom: 2px solid #d1d5db !important;
  input {
    // font-family: 'tradeGothic';
    font-size: 15px !important;
    background: transparent !important;
    line-height: 1;
  }
}

.underlinedPhone {
  outline: none !important;

  padding: 5px 12px !important;
  background-color: #f4f4f4 !important;
  border: none !important;
  border-bottom: 2px solid #d1d5db !important;
  input {
    // font-family: 'tradeGothic';
    font-size: 15px !important;
    background: transparent !important;
    line-height: 1;
    border: none !important;
    padding-left: 36px !important;
  }
  .flag-dropdown {
    outline: none !important;

    border: none !important;

    background: transparent !important;
    border-bottom: 1px solid #d1d5db;
  }
  .flag-dropdown div {
    border: none !important;

    background-color: transparent !important;
  }
}

.underlined {
  .ant-input-number {
    border: 0px;
  }
}
.inputNumber {
  .ant-input-number {
    width: 100%;
  }
  input {
    padding: 12px;
    height: auto;
  }
}

.paddingLeft40 {
  padding-left: 40px !important;
}

.errorLabel {
  font-weight: 400;
  font-size: 14px;
  color: red;
}

.flatCheckbox {
  span {
    font-weight: 400;
    font-family: tradeGothic;
    font-size: 16px;
  }
}

.ant-form-item-has-error * {
  border-color: #ff4d4f !important;
}

.ant-picker:not(.ant-picker-disabled) {
  .ant-picker-suffix {
    .anticon-clock-circle {
      color: #323232;
    }
  }
}

.phoneNumber {
  input:disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: whitesmoke;
  }
}


.input-noborder input{
  border-bottom: none !important;
}