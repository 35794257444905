.settingsContainer {
  height: 100%;
  background-color: #e5e8ee;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 32px;
}

.r-4px{
  border-radius: 4px;
  overflow: hidden;
}