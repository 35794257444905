// Stripe Stuff
/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.stripeElementsLabel {
  color: #000;
  padding-top: 8px;
  padding-bottom: 6px;
  font-weight: 700;
  line-height: 1;
  font-size: 1rem !important;
  padding-left: 0px;
  font-family: tradeGothic !important;
}

.StripeElement {
  // height: 40px;
  padding: 15px 12px;
  width: 100%;
  color: #32325d;
  background-color: #f4f4f4;
  border-bottom: 1px solid #d1d5db;
  // border-radius: 4px;

  // box-shadow: 0 1px 3px 0 #e6ebf1;
  // -webkit-transition: box-shadow 150ms ease;
  // transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  // box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
//
