.giftCardsRow {
  display: flex;
  justify-content: space-between;
}

// GiftCardsList start
.giftCardsList {
  & .giftCardItem {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 14px;
    margin-bottom: 14px;
  }
}

// GiftCardsList End
.giftcardFeedbackAlert {
  margin-bottom: 10px;
}


.balanceContainer{
  display: flex;
  flex-direction: column;
  gap: 12px;
}