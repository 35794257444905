.passwordWithDropdown {
  .ant-form-item-explain {
    // display: none !important;
  }
}

.passwordPopup {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.7);
  //   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  background-color: #fff;
}

.passwordErrorsInfo {
  margin-bottom: 4px;
}

.passwordErrors{
    font-weight: 600;
}

.passwordErrorLine {
}
