.nurseSelectorContainer {
    .label {
      font-size: 16px;
      font-weight: 700;
    }
  }
  
  .nurseSelector .ant-select-selector {
    height: auto !important;
    border: 2px solid #d8dce4 !important;
    border-radius: 6px !important;
    padding: 10px 16px !important;
    //   padding-bottom: 6px !important;
    width: 240px !important;
    input {
      height: auto !important;
      padding: 10px 16px !important;
      padding-left: 6px !important;
    }
  
    * {
      font-size: 18px !important;
    }
  }
  
  .nurseSelectorDropdown {
    border-radius: 6px;
    .ant-select-item {
      // font-size: 18px !important;
      padding: 6px 16px !important;
    }
  }
  