.settingCard {
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0px 5px 10px #dfe2e8;
  
    transition: all 0.25s ease-out;
    &:hover {
      box-shadow: 0px 5px 10px #9d9fa4;
    }
  
    .settingCardTitle {
      font-size: 18px;
      color: #15213b;
      font-family: tradeGothic, roboto, sans-serif;
    }
    .settingCardText {
      font-family: tradeGothic, roboto, sans-serif;
      color: #7f889a;
      line-height: 22px;
    }
  }
  