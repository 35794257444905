.card_container {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
}

.color_pink {
  img {
    width: 200px;
    height: auto;
    max-width: inherit;
  }
}

.dashboard-cards {
  width: 100% !important;
  display: flex !important;
  flex-flow: row wrap;

  .ant-col {
    margin: 0 0 16px !important;
  }

  .dash-card {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    background: #440A33 !important;
  }

  .dash-title {
    font-size: 15px !important;
    line-height: 22px !important;
    font-weight: 400 !important;

    &:only-child {
      width: 100%;
      text-align: center;
    }
  }

  .dash-num {
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

@media (max-width: 767px) {
  .color_pink {
    img {
      width: 125px;
    }
  }

  .dashboard-cards {
    .dash-title {
      font-size: 13px !important;
    }

    .dash-num {
      width: 70px !important;
      height: 40px !important;
      font-size: 15px !important;
      line-height: 20px !important;
    }
  }
}