#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

@font-face {
  font-family: "hendersonBasic";
  src: url("../../assets/fonts/HendersonSansBasicLight.otf");
}
@font-face {
  font-family: "hendersonBold";
  src: url("../../assets/fonts/HendersonSansBasicBold.otf");
}
@font-face {
  font-family: "hendersonSemiBold";
  src: url("../../assets/fonts/HendersonSansBasicSemiBold.otf");
}
@font-face {
  font-family: "rift";
  src: url("../../assets/fonts/Fort\ Foundry\ -\ Rift-Regular.otf");
}
@font-face {
  font-family: "tradeGothic";
  src: url("../../assets/fonts/Trade\ Gothic\ LT.ttf");
}
@font-face {
  font-family: "tradeGothicBold";
  src: url("../../assets/fonts/Trade\ Gothic\ LT\ Bold\ No.\ 2.ttf");
}

.appBookAppointmentButton{
  span{
    font-family: tradeGothic;
    font-size: 14px !important;
    
  }
  padding-left: 26px !important;
  padding-right: 26px !important;
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}
.logo {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-bottom: 1px solid #d8dce4;
  border-right: 1px solid #d8dce4;
  img {
    width: 130px;
  }
  // margin-left: 20px;
}
.ant-table-thead > tr > th {
  background-color: #f6f7fa !important;
  color: #000 !important;
  font-family: "TradeGothic" !important;
  font-weight: 700;
  font-size: 16px;
}
.headerAvatar {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #486baf;
  & > :first-child {
    font-family: "tradeGothic";
    font-weight: 700;
    color: #fff;
  }
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 4px;
  border-radius: 10px !important;
  scrollbar-width: thin;

}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(175, 175, 175);
  // border-radius: 5px;
}

.colorPrimary {
  color: #486baf !important;
}

.color-black {
  color: #000 !important;
}

.desktopSidebar {
  .logo img {
    transition: width 0.25s ease-in;
  }
  &.ant-layout-sider-collapsed {
    .logo img {
      width: 50px;
    }
  }
}

.formTextContent {
  &,
  * {
    font-family: "tradeGothic";
    font-size: 18px;
  }
  ul {
    list-style-position: inside;
    padding-left: 0;
  }
}

.firstLetterUppercase{
  text-transform: capitalize;

}

