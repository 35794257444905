.controls {
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
}
.checkmark {
  background-color: #27ca2d;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  border-radius: 24px;
  // position: absolute;
  // right: 12px;
  // top: 12px;
}

.card {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 10px 10px rgba(22, 31, 58, 0.08);
  background: #fff;
  margin: 12px 0px;
  padding: 16px;
  cursor: default;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  flex-direction: row;
  min-height: 176px;
  position: relative;

  .section1 {
    display: flex;
    flex-direction: column;
    flex: 1;

    border-right: 2px solid #e5e8ee;
    border-bottom: none;
    justify-content: center;
    width: auto;
    padding: 0;
    padding-left: 8px;
  }

  .section2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    // height: 100%;
    padding: 0;
    padding-left: 20px;
    border-right: 2px solid #e5e8ee;
    border-bottom: none;
    justify-content: space-between;
    width: auto;
  }

  .section3 {
    flex: 2 1 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    height: 100%;
    padding: 0;
    padding-left: 20px;
  }
}

.largeFont {
  font-family: "tradeGothic";
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.normalFont {
  font-family: "tradeGothic";
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.smallFont{
  font-family: "tradeGothic";
  font-size: 14px !important;
  font-weight: normal;
  margin: 0; 
}

.boldFont {
  font-family: "tradeGothic";
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.signatureText {
  font-size: 14px;
  font-style: italic;
  margin: 0;
  font-family: "tradeGothic";
}

.signaturesTextContainer {
  display: flex;
  align-items: center;
  gap: 14px;
}
.flexJustifyBetweenAlignEnd {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.selector {
  margin: 8px 0px 16px 0px;
  display: flex;
  align-items: center;
  border: 2px solid #dbdfea;
  border-radius: 4px;
  height: 46px;
  max-width: 380px;
}

.badge {
  width: 85px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 27px;
  font-family: "tradeGothic";
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.linkBtn {
  text-align: left;
  padding: 0px;
  font-family: "tradeGothic";
  font-size: 16px;
}

.noGFEContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.noGFETextContainer {
  display: flex;
  gap: 4px;
}

.exclaimationIcon {
  color: orange;
  padding-top: 4px;
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .card {
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    min-height: auto;

    .section1 {
      border-right: none;
      border-bottom: 2px solid #e5e8ee;
      justify-content: flex-start;
      width: 100%;
      height: auto;
      padding: 10px 0px 16px 0px;
      padding-left: 0;
    }
    .section2 {
      padding: 16px 0px;
      padding-left: 0;
      border-right: none;
      border-bottom: 2px solid #e5e8ee;
      justify-content: center;
      width: 100%;
      height: auto;
    }
    .section3 {
      width: 100%;
      height: auto;
      padding: 16px 0px;
      padding-left: 0;
      height: auto;
    }
  }
}
