.voucherAppliedContainer {
  margin-top: 38px;
}
.checkMarkOutlined {
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #27ca2d;
  svg {
    width: 12px;
  }
  path {
    stroke: #27ca2d;
  }
}
.voucherCloseBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  background-color: #f4f4f4 !important;
  border: #f4f4f4;
  margin-left: 12px;
}
.availedText {
  line-height: 1 !important;
  font-size: 18px !important;
  color: #27ca2d !important;
  margin-bottom: 0px !important;
}