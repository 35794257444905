.locationFormItem {
  &.ant-form-item-has-error{
    border-color: red !important;
  }
  @media screen and (max-width: 768px) {
    & > * {
      flex: none !important;
    }
  }
  div {
    label {
      line-height: 1;
      font-size: 1rem !important;
      padding-left: 0px;
      font-family: "tradeGothic" !important;
    }
  }
}
.addressLocation {
  font-family: "tradeGothic" !important;
  display: block;
  div {
    input {
      background: red !important;
      height: 47px !important;
    }
  }
}
.round {
  div {
    label {
      line-height: 1;
      font-size: 15px !important;
      padding-left: 8px;
      padding-top: 12px !important;
      font-family: "hendersonSemiBold" !important;
    }
  }
}
.pac-container{
  width: 500px !important;
}