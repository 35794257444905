
.settingsHeaderContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  margin-bottom: 16px;
}

.settingsHeaderLeftSide {
  display: flex;
  align-items: center;
  gap: 6px;
  * {
    line-height: 0;
  }
}
