.shiftScheduler {
  .rbc-calendar {
    margin-top: 0;
  }
  .rbc-month-view {
    overflow-y: scroll;
    .rbc-month-row {
      min-height: 200px; // 200px
    }

    .rbc-row-content {
      .rbc-row:nth-child(1) {
        height: 32px;
      }
      .rbc-row:not(:nth-child(1)) {
        height: 55px;
      }
      .rbc-row:nth-child(4) {
        height: 50px;
      }
    }
    .rbc-show-more {
      outline: none !important;
    }
  }
  .shiftHeaderCell {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-family: tradeGothic;
  }
  .shiftCellDay {
    text-align: left;
    padding-left: 10px;
    padding-top: 5px;
    font-family: "tradeGothic";
    font-weight: 700;
    font-size: 14px;
  }
  .rbc-row {
  }
  .rbc-row-segment {
  }
}
.shiftSchedulerItem {
  height: 50px;
  width: 100%;
  background-color: #dae1ef;
  border-radius: 4px;
  padding: 0px 10px;
  color: #161f3a;
}
.shiftSchedulerItem.isRejected {
  background-color: #f14b4b;

}

.shiftScheduleShowMorePopover {
  min-width: 200px;
  .ant-popover-inner-content > *:not(:last-child) {
    margin-bottom: 5px;
  }
}

.paddedContainer {
  padding: 0px 10px;
}

.shiftScheduleShowMore {
  display: flex;
  background: #dae1ef;
  margin-top: 7px;
  margin-left: 11px;
  padding: 12px 10px;
  border-radius: 5px;
  font-family: tradeGothic;
  color: #161f3a;
}

// Shift Scheduler Toolbar start
.shiftSchedulerToolbar {
  background-color: #e5e8ee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  padding-top: 0px;
}
// Shift Scheduler Toolbar end


// Schedule shift modal start
.scheduleShiftModal{
  .ant-modal-body{
    padding-top: 12px;
  }
}

.scheduledStaffList{
  .ant-list-header{
    display: flex;
    *{
      font-family: tradeGothic;
      font-weight: 700;
    }
  }
  input{
    padding: 8px 12px !important;
  }
}
// Schedule shift modal end
