.cancellationPolicyHead {
  display: flex;
  justify-content: space-between;
}

// Policy List Start
.cancellationPolicyList {
  padding-top: 12px;
  .ant-list-item {
    padding: 0;
    margin-bottom: 20px;
    border-bottom: none;
  }
}

.cancellationPolicyCard {
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
  border: 2px solid #dfdfdf;
  border-radius: 4px;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.07);
}

.cancellationPolicyModal {
  .ant-modal-title {
    font-family: tradeGothic !important;
  }
  .ant-modal-body {
    padding: 12px 30px;
  }
}

.cancellationTab {
  padding-top: 2px;
  border-top: 1px solid #f0f0f0;
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      font-weight: 700;
    }
  }

  .ant-tabs-tab-btn {
    font-family: tradeGothic;
  }
}
