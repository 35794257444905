.inventory {
  margin-top: -16px;
  height: 100%;
  background-color: #e5e8ee;
  .head {
    height: 80px;
    background: #e5e8ee;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .body {
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
    height: 100%;
    padding-bottom: 100px;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 768px) {
  .kitSearch {
    flex-direction: column;
  }
}
.kitSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;

  gap: 8px;

  .userInfo {
    display: flex;
    gap: 10px;
    .names {
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & > *:first-child {
        margin-bottom: 0px;
        color: #000 !important;
        font-size: 19px;
      }
      & > *:nth-child(2) {
        color: #000 !important;
        font-family: tradeGothic !important;
        font-size: 15px !important;
      }
    }
  }
  .middle {
  }
  .right {
  }
}
