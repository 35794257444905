.container {
  background: #161f3a;
  padding: 0px 11px;
  padding-right: 24px;
  box-shadow: border-box;
  height: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 12px;
}

.circle {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #486baf;
  color: #fff;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .container {
    height: 40px;
    line-height: 100%;
  }
  .circle{
    height: 26px;
    width: 26px;
  }
}
