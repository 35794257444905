.mobileSideDrawer {
  .ant-menu {
    background-color: transparent;
    border: none;
    .ant-menu-item {
      padding-left: 14px !important;
      border-radius: 4px;
      &::after {
        border: none !important;
      }
      span {
        color: #d9dadf;
      }
      span [role="img"] {
        color: #fff;
      }
    }
    .ant-menu-item-selected {
      background-color: #3f475e;
      font-weight: 700;
    }
  }

  .ant-drawer-content {
    background-color: #161f3a;
  }
  .ant-drawer-body {
    padding-top: 84px;

    background-color: #161f3a;
  }
  .ant-drawer-mask {
    background-color: rgba(22, 31, 58, 0.7);
  }
}

.drawerFooterLink {
  display: flex;
  align-items: center;
  padding-left: 14px;
  cursor: pointer;
  & :first-child {
    margin-right: 4px;
  }
  &:hover {
    span {
      color: #fff;
    }
  }
}
