@import "./variables";
@import "./reset";

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: $out-of-range-color;
}

.rbc-off-range-bg {
  background: $out-of-range-bg-color;
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid $cell-border;

  & + & {
    border-left: 1px solid $cell-border;
  }

  .rbc-rtl & + & {
    border-left-width: 0;
    border-right: 1px solid $cell-border;
  }

  & > a {
    &,
    &:active,
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.rbc-button-link {
  color: inherit;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: text;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
}

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;

  .rbc-row-content-scroll-container {
    height: 100%;
    overflow-y: scroll;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.rbc-today {
  background-color: $today-highlight-bg;
}

.rbc-timeslot-group {
  z-index: 1;
}
.rbc-event {
  z-index: 2;
}

div.bg-red {
  background: linear-gradient(180deg, #f5222d 0px, #dd1f28 220px) !important;
  // border-color: #fff !important;
  // border: none !important;
  color: #dd1f28 !important;
}
.cl-red {
  color: #f5222d !important;
}
div.bg-blue {
  background: linear-gradient(180deg, #486baf 0px, #325497 220px) !important;
  // border-color: #fff !important;
  // border: none !important;
  color: #325497 !important;
}
.cl-blue {
  color: #325497 !important;
}
div.bg-orange {
  background: linear-gradient(180deg, #fa8c16 0px, #e98112 220px) !important;
  border-color: #fff !important;
  // border: none !important;
}
.cl-orange {
  color: #e98112 !important;
}
div.bg-green {
  background: linear-gradient(180deg, #78a089 0px, #6e927d 220px) !important;
  // border-color: #fff !important;
  // border: none !important;
}
.cl-green {
  color: #6e927d !important;
}
.cl-lgreen {
  color: #27ca2d !important;
}
div.bg-purple {
  background: linear-gradient(180deg, #722ed1 0px, #6219c7 220px) !important;
  // border-color: #fff !important;
  // border: none !important;
  color: #6219c7 !important;
}
.cl-purple {
  color: #722ed1 !important;
}
div.bg-lime {
  background: linear-gradient(180deg, #5bb85d 0px, #45b547 220px) !important;
  // border-color: #fff !important;
  // border: none !important;
}

.ant-btn-dangerous span {
  color: rgb(255, 79, 81) !important;
}

@import "./toolbar";
@import "./event";
@import "./month";
@import "./agenda";
@import "./time-grid";
