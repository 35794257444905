// @import '../../style/colors';
.avatar_container {
  margin: 12px 0px;
  background: #490030;
  height: 15%;
  border: 1px solid #a80974;
  border-radius: 5px;
  display: flex;
  align-items: center;
  // padding: 24px;
  color: white;
  font-size: 16px;

  .save_button {
    margin-left: 0;
    margin-bottom: 10px !important;
    width: 275px !important;
  }
}

.avatar_card_container {
  .save_button {
    width: 275px !important;
  }
}

.profile-area {
  .avatar_container {
    height: 160px;
    padding: 0 20px;
    flex-wrap: wrap;

    @media (max-width: 1100px) {
      justify-content: center;
      height: auto;
    }
  }

  .avatar-wrap {
    position: relative;
    width: 150px;

    .remove_avatar {
      right: 10px;
      top: 10px;
    }
  }

  .avatar {
    position: relative;
    margin-left: 0;
    width: 134px;
    height: 134px;
    padding: 5px;
    background: #3d0028;
    border: 1px dashed #5f1f49;
    border-radius: 100%;
    display: block !important;

    @media (max-width: 1100px) {
      flex: inherit !important;
      margin: 15px auto;
    }

    img {
      border: 2px solid #a80974;
      padding: 0;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }

  .avatar_btn_sec {
    @media (max-width: 1100px) {
      display: block;
    }

    @media (max-width: 540px) {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      flex: inherit;
      flex-wrap: wrap;
      padding: 0 0 10px;
    }
  }

  .save-btn-text {
    @media (max-width: 540px) {
      width: 100%;
    }
  }
}

.remove_avatar {
  width: 20px;
  height: 20px;
  background: #212121;
  border-radius: 100%;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  transition: all 0.4s ease;

  &:hover {
    background: #930e6a;

    &:before,
    &:after {
      background: #fff;
    }
  }

  &:after,
  &:before {
    width: 2px;
    height: 12px;
    border-radius: 2px;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #b2a5b2;
    position: absolute;
    transition: all 0.4s ease;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.avatar {
  width: 188px;
  height: 188px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;

  @media (max-width: 1100px) {
    flex: 1;
  }

  img {
    border: 4px solid #000;
    border-radius: 50%;
    padding: 2px;
  }
}

.avatar_card_container {
  margin: 12px 0px;
  background: #490030;
  position: relative;
  border: 1px solid #a80974;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 12px;
  color: white;
  overflow-x: auto;
  @media (max-width: 1850px) {
    justify-content: center;
    flex-wrap: wrap;
    // flex-direction: column;
  }
  @media (max-width: 1100px) {
    flex-wrap: wrap;
    // flex-direction: column;
  }
}

.card-photo-holder {
  padding: 20px;

  .avatar_card {
    // background: url("../../assets/images/default-card-photo.png") no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin: 0 20px 0 0;
    width: 230px;
    height: 280px;

    @media (max-width: 650px) {
      width: 190px;
      height: 250px;
    }

    @media (max-width: 540px) {
      margin: 0 0 20px;
    }

    &:hover {
      .remove_avatar {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .save_button {
    margin-left: 0;
    margin-bottom: 10px !important;
    width: 236px !important;
  }
  .remove_avatar {
    right: 7px;
    top: 7px;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
  }
}

.avatar_card {
  border-radius: 20px;
  width: 230px;
  height: 280px;
  border: 2px dashed #603a53;

  background-size: 100% 100%;
  background-position: center;
  position: relative;
  margin: 16px;
  padding: 8px;
  > div {
    overflow: hidden;
  }
  img {
    // width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 1850px) {
    width: 45%;
    height: 380px;
  }
  @media (max-width: 1600px) {
    width: 45%;
    height: 500px;
  }
  @media (max-width: 1400px) {
    width: 30%;
    height: 300px;
  }
  @media (max-width: 1300px) {
    width: 40%;
    height: 300px;
  }
  @media (max-width: 1150px) {
    width: 70%;
    height: 500px;
  }

  @media (max-width: 1100px) {
    width: 38%;
    margin: 8px;
    // height: auto;
  }
  @media (max-width: 850px) {
    width: 32%;
    margin: 8px;
    height: 316px;
    // height: auto;
  }
  @media (max-width: 750px) {
    width: 30%;
    margin: 16px;
    height: 300px;
  }
  @media (max-width: 650px) {
    width: 60%;
    height: 450px;
    margin: 8px;
    // height: auto;
  }
  @media (max-width: 500px) {
    width: 60%;
    height: 320px;
    margin: 8px;
    // height: auto;
  }
  @media (max-width: 360px) {
    width: 100%;
    margin: 8px;
    // height: auto;
  }
}

.avatar_btn_sec {
  flex: 1;
  @media (max-width: 1100px) {
    display: none;
  }
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}

input[type="file"] {
  color: #fff;
}

.ant-modal-body input[type="file"] {
  margin: 0 0 10px;
}

@keyframes example {
  from {
    transform: translate(100%, 0px);
  }
  to {
    transform: translate(0px, 0px);
  }
}

// .anticon svg{
//   color: white !important;
// }

.profile-area {
  position: relative;
  overflow: hidden;
  margin: 0 0 23px;
}

.btn-uploader-holder {
  padding: 20px 0;
  border-top: 2px dashed #8d6c83;
  border-bottom: 2px dashed #8d6c83;
  text-align: center;

  .label-profile {
    display: inline-block;
    vertical-align: top;
    background: #486baf;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    cursor: pointer;
    padding: 10px 15px;
    transition: all 0.4s ease;

    &:hover {
      background: #8db3ff;
    }

    input[type="file"] {
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }
  }
}

.avatar_photo_container {
  overflow: hidden;

  .avatar_card {
    padding: 5px;

    @media (max-width: 1850px) {
      margin: 5px 2px;
    }

    .uploaded-image {
      img {
        border: 4px solid #820256;
        border-radius: 15px;
      }
    }
  }

  .custom-modal-close {
    width: 24px !important;
    height: 24px !important;
    background: #212121;
    border: 1px solid #000;
    cursor: pointer;
    right: 14px;
    top: 14px;
    padding: 5px 4px 5px 4px;
  }

  .uploaded-image {
    position: relative;
    z-index: 3;
  }

  .avatar_card__img {
    width: 60px;
    margin: 0 auto 15px;

    img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }

  .avatar_card__info {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    padding: 0 10px;
    pointer-events: none;
    transform: translate(0, -50%);
    text-align: center;
    color: #930e6a;
    font-size: 18px;
    line-height: 21px;
  }
}

.label-profile__text {
  color: #fff;
}

.uploadImageBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  
  color: #486baf !important;
  background-color: #fff !important;
  height: auto;
  border-radius: 50px;
  border-color: #D8DCE4;
  span{
    line-height: 1;
    font-family: "tradeGothic";
    font-weight: 400;
    font-size: 15px;

  }
}
