.consentModal {
  .ant-modal-footer {
    padding-top: 12px !important;
    padding-bottom: 18px !important;
  }
}
.consentForm {
  ul,
  ol {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    margin-bottom: 6px;
    list-style-type: none;

    li {
      margin-left: 12px;
      font-family: inherit;
      font-size: inherit;
      color: inherit;
      line-height: inherit;
      margin-bottom: 6px;
    }
  }

  span.ant-typography {
    color: #000;
    font-family: "tradeGothic";
    span {
      display: block;
      margin-bottom: 6px;
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }
    span:last-child {
      margin-bottom: 0;
    }
  }

  ul {
  }

  .flatFormItemCheckbox {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.boldRadio {
  font-weight: 700 !important;
  * {
    font-weight: 700 !important;
  }
}
