// Variables
@import "variables";

// Component syles
@import "react-star-rating";
@import "react-select";
@import "react-bootstrap-slider";
@import "react-date-picker";

@import "react-draft";

// Form builder styles
@import "form-builder";
@import "form-builder-form";



.formCreateModal{
    form{
        // padding-bottom: 16px;
        // border-bottom: 1px solid #BDC6DD;
    }
    .ant-modal-footer{
    }
    .ant-modal-title{
        font-family: tradeGothic !important;
    }
}