.switchComponent {
  height: 32px;
  width: 64px;
  background-color: transparent;
  border: 1px solid #7f889a;

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 24px - 3px);
  }

  &[aria-checked="false"] {
    .ant-switch-inner {
      margin: 0 0px 0 25px;
    }
    .ant-switch-handle::before {
      background-color: #e5e8ee;
    }
  }
  &[aria-checked="true"] {
    .ant-switch-inner {
      margin: 0 25px 0 0px;
    }
    .ant-switch-handle::before {
      background-color: #27ca2d;
    }
  }

  .ant-switch-handle {
    height: 24px;
    width: 24px;
    top: 3px;
    left: 3px;
    &::before {
      border-radius: 50px;
      box-shadow: none;
    }
  }

  .ant-switch-inner {
    color: #000;
    font-size: 14px;
    font-family: "tradeGothic";
  }
}
