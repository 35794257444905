.locationSelectorContainer {
  display: flex;
  max-width: 250px;
  min-width: 150px;
  flex-grow: 1;
  margin-right: 16px;
  .locationSelectorIcon {
    // border: 2px solid #d8dce4 !important;
    border-radius: 6px 0px 0px 6px !important;

    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 50;
    padding: 0px 10px;
    padding-right: 0px;
  }
}
.locationSelector {
  display: flex;
  flex-grow: 1;
}

.locationSelector .ant-select-selector {
  height: auto !important;
  border: 2px solid #d8dce4 !important;
  border-radius: 0px 6px 6px 0px !important;
  padding: 10px 16px !important;
  min-width: 200px;
  //   padding-bottom: 6px !important;\
  width: 100% !important;
  max-width: calc(250px - 24px) !important;
  outline: none !important;
  box-shadow: none !important;
  display: flex;
  flex-grow: 1;
  input {
    background: "red";
    width: 500px;
    // height: auto !important;
    padding: 10px 16px !important;
    padding-left: 6px !important;
  }

  * {
    font-size: 18px !important;
  }
  @media screen and (max-width: 768px) {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
}

.locationSelectorDropdown {
  border-radius: 6px;
  .ant-select-item {
    // font-size: 18px !important;
    padding: 6px 16px !important;
  }
}
