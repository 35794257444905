.locationInput {
  border: none;
  font-family: "tradeGothic";
  font-size: 20px;
  // font-weight: bold;
  color: #666;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  // border-radius: 8px;
  margin-left: 2px;
  &::placeholder {
    color: #bfbfbf !important;
    // font-size: 18px;
    font-family: sans-serif; // rest of the form has san-serif
  }
  &:focus-visible {
    outline: none;
  }
}

.locationDropdown {
  display: flex;
  align-items: center;
  input {
    font-family: Roboto, sans-serif;
    font-size: 18px;
  }
  .suffixIcon {
    height: 100%;
    padding: 0px 12px;
  }
}

.flatFormItem {
  .locationInput {
        font-family: Roboto, sans-serif;

    font-size: 14px;
  }
}
