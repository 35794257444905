.whole {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  .whole_left {
    background: #798bad;
    // background: url("https://topica.asia/uploads/images/shutterstock_227863141.jpg")  no-repeat  ;
    // background-size: cover;
    width: 65%;
    // padding: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 992px) {
      display: none;
    }
  }
  .whole_right {
    display: flex;
    margin-top: 5%;
    // justify-content: center;
    // align-items: center;
    flex-direction: column;
    width: 35%;
    @media only screen and (max-width: 992px) {
      align-items: center;
      width: 100%;
    }
  }
  .whole_right_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 400px;
    width: 100%;
    padding: 0 20px;

    form {
      width: 100%;
    }
  }
}
.wellcomeText {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  @media screen and (max-width: 992px) {
    font-size: 20px;
  }
}
