.outlinedIncrementor {
  display: flex;
  border: 2px solid #d8dce4;
  border-radius: 6px;
  overflow: hidden;
  width: 190px;
  .disabled{
    cursor: default !important;
    background-color: #f5f5f5 !important;
  }
  * {
    border: none;
  }
  & > :first-child {
    height: 40px;
    width: 40px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-right: 2px solid #d8dce4;
    &:hover {
      cursor: pointer;
      background-color: #e9f0ff;
    }
  }
  & > :nth-child(2) {
    text-align: center;
    width: 110px;
    margin: 0px;
    input {
      width: 100%;
      text-align: center;
      padding: 9px 0px;
      outline: none !important;
    }
  }
  & > :last-child {
    height: 40px;
    width: 40px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-left: 2px solid #d8dce4;
    &:hover {
      cursor: pointer;
      background-color: #e9f0ff;
    }
  }
}
