.dropdownButton {
  box-sizing: border-box;
  color: #fff;
  background-color: #486baf;

  height: 50px;
  border-radius: 50px;

  display: flex;
  align-items: center;

  button[ant-click-animating-without-extra-node="true"]::after {
    display: none !important;
  }

  button:first-child {
    font-size: 17px;
    line-height: 1;
    color: #fff;
    background-color: transparent;
    border: none;
    padding-left: 24px;
    padding-right: 24px;
    &:hover {
      background-color: transparent;
      color: #fff;
    }
    * {
      font-family: "tradeGothicBold";
      font-weight: 700;
    }
  }

  .ant-btn-icon-only {
    width: auto;
    padding-left: 16px;
    padding-right: 20px;
    height: 100%;
    color: #fff;
    border: none;
    background-color: transparent;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    *{
        color: #fff;
        stroke: #fff;
    }
  }
}
