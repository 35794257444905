.integrationAddBtn {
  padding: 0px;
  height: auto;
  span {
    line-height: 1;
  }
}

.integrationDivider {
  margin-top: 18px;
  border-width: 2px;
  border-color: #eff1f4;
}

.integrationDrawer {
  .ant-drawer-header {
    padding-top: 8px;
  }
  .ant-drawer-body {
    padding-top: 0px;
  }

  .integrationDrawerTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    color: #000;
    border-bottom: 2px solid #eff1f4;
    p {
      margin: 0;
      font-size: 24px;
      font-family: tradeGothic;
      font-weight: bold;
    }
  }
}
.integrationDrawerFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #000;
}
// List Styling Start
.integrationList {
  .ant-list-item {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    border: none;
  }
}

// List Styling End

// Data Row Styles Start
.domainRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.domainRowControls {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: -8px;
  button {
    background-color: transparent !important;
    color: #000 !important;
    border: none !important;
    box-shadow: none !important;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
// Data Row Styles End

.jounalEntryRadioContainer {
  .ant-space-item {
    line-height: 1;
  }
}

.journalEntriesDetailDrawer {
  .ant-drawer-header {
    padding-top: 8px;
  }
  .ant-drawer-body {
    padding-top: 0px;
  }

  .journalEntriesDetailTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    color: #000;
    border-bottom: 2px solid #eff1f4;
    p {
      margin: 0;
      font-size: 24px;
      font-family: tradeGothic;
      font-weight: bold;
    }
  }
  .ant-drawer-body {
    overflow-y: scroll;
  }
}
