.addressCard {
  width: 100%;
  border-bottom: 1px solid #cddfd5;
  &:not(:first-child) {
    padding-top: 6px;
  }
  padding-bottom: 10px;

  .addressCardFirstRow {
    width: 100%;

    display: flex;
    justify-content: space-between;
  }
  .addressSecondRow {
    width: 100%;

    display: flex;
    justify-content: space-between;
  }
}
.addressesContainer {
  .ant-btn-text {
    padding: 0px;
  }
}

.addressInstructions {
  font-size: 14px !important;
}

.addressesLoading {
  padding-top: 6px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
