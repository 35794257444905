.profileTabsContainer {
  :global {
    span {
      font-weight: 400;
    }
    .ant-tabs {
      height: 100%;
      .ant-tabs-content {
        height: 100%;
      }
      .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              .ant-tabs-tab-btn {
                font-size: 18px !important;
                font-weight: bold !important;
                font-family: "tradeGothic" !important;
              }
            }
          }
        }
      }
    }

    .ant-form-item-explain-error {
      font-weight: 400;
    }
  }
}

.hidden {
  height: 0px !important;
  overflow: hidden !important;
}

.profileDrawer {
  :global {
    .ant-drawer-header {
      padding-top: 30px;
      padding-bottom: 0px;
    }
    .ant-drawer-body {
      padding-top: 0px !important;
      overflow: auto !important;
    }
  }
}

.mainTab {
  :global {
    .ant-tabs-nav {
      margin-bottom: 0px;
    }
  }
}

.documentsSearchbar {
  padding: 13px;
  font-size: 17px;
  line-height: 1;
  :global {
    .ant-input-prefix {
      margin-right: 8px;
    }
  }
}

.documentsList {
  :global {
    .ant-list-header {
      padding-top: 8px;
      padding-bottom: 8px;
      border-top: 1px solid #eff1f4;
      border-bottom: 1px solid #eff1f4;
    }
  }
}

.documentListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0px;
  border-bottom: 1px solid #eff1f4;
  line-height: 1;

  & > :first-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 8px;
  }
  & > :nth-child(2) {
    white-space: nowrap;
  }
}

// appointment tab container style start
.appointmentTabContainer {
  width: calc(100% - 2 * 24px);
  position: absolute;
  z-index: 1000;
  background-color: #fff;
}

// appointment tab container style end

.emailBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.emailBtn:hover {
  background: #fff;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
