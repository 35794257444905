.tableSearchbox {
  &.hasValue {
    button {
      background-color: #486baf !important;
      color: #fff !important;
    }
  }
}

.tableFilterButton {
  &.hasValue {
    background-color: #486baf !important;
    color: #fff !important;
  }
}
.tableSearchboxContainer{
  .ant-input-affix-wrapper{
    line-height: 1.5715 !important;
  }
}
