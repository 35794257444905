.serviceDrawer, .boostDrawer {
    .ant-drawer-header {
      padding-top: 8px;
    }
    .ant-drawer-body {
      padding-top: 4px;
    }
  
    .drawerTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      color: #000;
      border-bottom: 2px solid #eff1f4;
      p {
        margin: 0;
        font-size: 24px;
        font-family: tradeGothic;
        font-weight: bold;
      }
    }
  
    .drawerFooter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      color: #000;
    }
  }