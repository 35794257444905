.tag {
  background-color: #486baf;
  padding: 6px 16px;
  border: none;
  border-radius: 50px;
  font-family: tradeGothic;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  line-height: 1;
}
