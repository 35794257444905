.rbc-calendar {
  height: calc(100vh - 86px);
  margin-top: -10px;
  // width: calc(100vw - 321px);
}

.toolbar {
  background-color: #e5e8ee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  .toolbarSizer {
    display: flex;
    flex-grow: 1;
    max-width: 340px;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 768px) {
  .rbc-calendar {
    height: calc(100vh - 50px);
    // width: calc(100vw - 321px);
  }

  .toolbar {
    flex-direction: column;
    gap: 4px;
  }
  .toolbar > * {
    margin-right: 0px !important;
  }
  .viewSelector {
    height: 41px !important;
  }
  .calenderFilterBtn {
    height: 41px !important;
    min-width: 41px !important;
    padding: 0px !important;
  }
  .views {
    width: 41px !important;
    padding: 0px !important;
  }
}

.rbc-timeslot-group {
}
.rbc-today {
  background-color: #fff;
}
.rbc-current-time-indicator {
  background-color: transparent;
}
.dayWeekSelectorContainer {
  overflow: hidden;
  display: flex;
  background-color: #fff;
  border: 2px solid #d8dce4;
  border-radius: 6px;
  height: 54px;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    height: 41px;
  }

  & > :nth-child(2) {
    display: flex;
  }
  .arrows {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 21px;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
    &:first-child {
      border-right: 2px solid #d8dce4;
    }
    &:last-child {
      border-left: 2px solid #d8dce4;
    }
    @media screen and (max-width: 768px) {
      padding: 10px;
    }
  }

  .dateButton {
    all: unset;
    cursor: pointer;
    // height: 100%;
    height: 50px;
    line-height: 1;
    font-size: 18px;
    // width: 100%;
    color: #161f3a;
    padding: 0px 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 768px) {
      height: 38px;
      padding: 0px 6px;
    }
  }

  .hiddenDatePicker {
    height: 0px !important;
    width: 0px !important;
    padding: 0px !important;
    & > * {
      width: 0px !important;
      padding: 0px !important;
    }
  }
}

.viewSelector {
  // flex-grow: 1;
  max-width: 230px;
  display: flex;
  height: 54px;
  box-sizing: border-box;
  border: 2px solid #d8dce4;
  border-radius: 6px;
  overflow: hidden;

  .views {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    height: 100%;
    cursor: pointer;
    background-color: #fff;

    &:hover {
      background-color: #f5f5f5;
    }
    &.active {
      color: #fff;
      background-color: #161f3a;
      svg {
        stroke: #fff;
      }
    }
    &:not(.active) {
      color: #161f3a;
      svg {
        stroke: #161f3a;
      }
    }

    border-right: 2px solid #d8dce4;
    &:last-child {
      border-right: none;
    }
  }
}

.rbc-row.rbc-row-resource {
  border-bottom: none !important;
}

.shiftAvailable {
  background-color: #f1fdf6;
}

.rbc-allday-cell {
  display: none;
}
.rbc-time-view .rbc-header {
  border-bottom: none;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px;
}

.rbc-time-header-content:last-child {
  border-right: 1px solid #ddd;
}

.rbc-day-slot:last-child {
  border-right: 1px solid #ddd;
}

.calenderFilterBtn {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 54px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #d8dce4 !important;
  border-radius: 4px;
  min-width: 50px;

  &:hover {
    background-color: #f5f5f5;
  }
  &.active {
    color: #fff;
    background-color: #161f3a;
    svg {
      stroke: #fff;
    }
  }
  &:not(.active) {
    color: #161f3a;
    svg {
      stroke: #161f3a;
    }
  }

  border-right: 2px solid #d8dce4;
  &:last-child {
    border-right: none;
  }
}
