// available tags
.primaryTag {
  margin: 0;
  color: #fff;
  background: #486baf;
  border-radius: 50px;
  padding: 4px 14px;
  padding-right: 18px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;

  svg {
    margin-right: 8px;
  }
  span {
    font-family: "tradeGothic";
    font-weight: 700;
  }
}

.dangerTag{
  background-color: rgb(218, 18, 18) !important;
}