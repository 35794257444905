.availabilityContainer {
  // max-width: 1200px;
  // margin: 0 auto;
  .availabilityHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e5e8ee;
    padding-bottom: 6px;
    height: 65px;
  }
  .availabilityBody {
    background-color: #e5e8ee;
    height: calc(100vh - 160px);
    overflow-y: auto;
  }
}
.availabilityTitle {
  font-size: 2rem !important;
  margin: 0 !important;
  color: #15213b !important;
  font-family: tradeGothicBold !important;
}
@media (max-width: 768px) {
  .availabilityTitle {
    font-size: 1.5rem !important;
  }
}
.availabilityCard {
  padding: 32px 12px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.availabilityWidget {
  width: 420;
  border-top: 1px solid #d1d5db;
  border-left: 1px solid #d1d5db;
  border-radius: 4px;
  overflow: hidden;
  .availabilityHeader {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    background-color: #e5e8ee;
    & > * {
      height: 60px;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #d1d5db;
      &:last-child {
        border-right: 1px solid #d1d5db;
      }
    }
  }
  .availabilityBody {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    & > * {
      overflow: hidden;
      height: 60px;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-bottom: 1px solid #d1d5db;
      border-right: 1px solid #d1d5db;
      .shiftName {
        font-size: 11px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        padding: 0px 8px;
        text-align: center;
      }
    }
  }
}

// Availability Review Start
.availabilityReviewContainer {
  padding: 8px 16px;
  border: 2px solid #d1d5db;
  border-radius: 4px;
}

.availabilityReviewItem {
  padding: 6px 0px;
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 2px solid #d1d5db;
  }
  & :nth-child(1) {
    font-weight: 700;
    font-family: tradeGothic;
  }
  & :nth-child(2) {
    font-weight: 700;
    font-family: tradeGothic;
  }
}
