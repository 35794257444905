.drawerTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 68px;
  color: #000;
  border-bottom: 2px solid #eff1f4;
}

.drawerTitle {
  margin: 0;
  font-size: 24px;
  font-family: "tradeGothic";
  font-weight: bold;
}
.footerControlsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  color: #000;
}

.backBtn {
  margin: 0;
  font-size: 16px;
  font-family: "tradeGothic";
  color: #486baf;
  text-decoration: underline;
  cursor: pointer;
}

.blueHeading {
  margin: 0;
  font-size: 16px;
  font-family: "tradeGothic";
  font-weight: bold;
  color: #486baf;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textTitle {
  margin: 0;
  font-size: 18px;
  font-family: "tradeGothic";
  font-weight: bold;
}

.rewardContainer {
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discountAmount {
  color: #27ca2d;
  font-size: 18px;
  font-family: "tradeGothic";
  font-weight: bold;
}

.messageText {
  margin: 0;
  font-size: 20px;
  font-family: "tradeGothic";
  font-weight: bold;
  margin-bottom: 14px;
}

.tipBtnsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.tipBtn {
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
}

.tipInput {
  margin-bottom: 0;
  margin-top: 0;
  height: 60px;
  border-radius: 8px;
  border: 2px solid #d8dce4;
  display: flex;
  justify-content: center;
  align-items: center;
}
