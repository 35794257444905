@import "../../style/fonts";

.loginForm {
  
  // height: 320px;
  padding: 36px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
  font-family: $sourcesans;
  padding: 20px 20px !important;
  margin-bottom: 20px !important;
}

.forgotPassword {
  text-decoration: "underline";
  cursor: "pointer";
  margin-top: 12;
  width: "100%";
  text-align: "right";
}
