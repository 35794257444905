.programDrawer {
  .ant-drawer-title {
    div {
      padding-bottom: 4px;
    }
  }
  .ant-drawer-body {
    padding-top: 4px !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 450px !important;
  }
}

.programDrawerTitle {
  font-size: 24px;
  font-family: tradeGothic;
  font-weight: bold;
}

.programDrawerFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.programDrawerContent {
}


