.eventDrawer {
  .ant-drawer-title {
    div {
      padding-bottom: 4px;
    }
  }
  .ant-drawer-body {
    padding-top: 4px !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 520px !important;
  }
}

.eventDrawerTitle {
  font-size: 24px;
  font-family: tradeGothic;
  font-weight: bold;
}

// // StatusPill.js Start
// .statusPillContainer{
//   label{
//     margin-bottom: 0px !important;
//   }
//   .ant-form-item-row{
//     flex-direction: column;
//   }
// }
.statusPill {
  outline: none !important;
  box-shadow: none !important;
  * {
    outline: none !important;
    box-shadow: none !important;
  }
  label {
    margin: 0px !important;
    font-weight: 400 !important;
    padding: 8px 20px 0px 16px;
    display: inline-flex !important;
    height: 50px;
    box-sizing: border-box;
    font-family: "tradeGothic";
    font-size: 16px;
    border: 2px solid #d8dce4 !important;
    border-radius: 6px !important;
    user-select: none;

    &:hover {
      color: #000 !important;
    }

    &:not(:last-child) {
      border-right: 0px !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
    &:last-child {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-left-width: 2px !important;
    }
  }
  label.ant-radio-button-wrapper-checked {
    border-color: #d8dce4;
    border-left-color: #d8dce4 !important;
    color: #000;
    background-color: #f2f4f8;
  }
  label.ant-radio-button-wrapper-disabled span {
    color: rgba(0, 0, 0, 0.25) !important;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    display: none;
  }
}
// StatusPill.js End

// OutlineSelector.js Start
div.outlinedSelector {
  width: 100%;
  outline: none !important;
  box-shadow: none !important;
  * {
    outline: none !important;
    box-shadow: none !important;
  }
  & .ant-select:not(.ant-select-customize-input) div.ant-select-selector {
    border: 2px solid #d8dce4 !important;
    padding: 8px !important;
    padding-left: 16px !important;
    border-radius: 6px !important;
    height: auto !important;
  }
  .iconContainer {
    position: relative;
    width: 31px;
    padding-left: 10px;
    &::before {
      content: " ";
      position: absolute;
      left: 0;
      top: -19px;
      height: 50px;
      background-color: #d8dce4;
      width: 2px;
    }
  }
  .ant-select-selection-search-input {
    height: 100% !important;
    padding-left: 4px !important;
  }
}
// OutlineSelector.js End

// Collapse start
.eventNotesCollapse {
  .ant-collapse-header {
    padding-left: 0px !important;
  }
  .ant-collapse-content {
    border-top: 2px solid rgb(239, 241, 244) !important;
  }
  .ant-collapse-item {
    border-radius: 0px !important;
    border-bottom: 2px solid rgb(239, 241, 244) !important;
  }
  .ant-collapse-content-box {
    padding: 16px 0px !important;
  }
}
// Collapse end
