.newBookingDrawer {
}
.newBookingDrawer {
  .ant-drawer-title {
    div {
      padding-bottom: 8px;
    }
  }
  .ant-drawer-body {
    padding-top: 4px !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 500px !important;
  }
}

// NewBookingCard.js start
.newBookingCard {
  border: 1px solid #d8dce4;
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
  &.focused {
    // cursor: default;
    background-color: #f1f4fb;
    border-color: #d8dce4;
  }
}
// NewBookingCard.js end
