.checkboxContainer {
  display: flex;
  flex-direction: column;
}

.checkbox {
    display: flex;
    align-items: center;
    span:nth-child(2) {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
}
