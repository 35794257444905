* {
  font-family: "Roboto", sans-serif;
}

.app {
}

// ::-webkit-scrollbar {
//   width: 0px;
//   height: 0px;
// }
.ant-btn-text {
  span {
    color: #486baf;
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "tradeGothic", sans-serif;
  color: #161f3a;
}
body h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  font-family: "tradeGothic", sans-serif;
}

span.ant-typography {
  font-family: "tradeGothic", sans-serif;
  font-size: 16px;
}

// customizing modal
.antdModal {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-header {
    padding: 26px 30px;
    padding-top: 30px;
    .ant-modal-title {
      color: #000;
      font-family: "tradeGothic";
      font-weight: 600;
      font-size: 1.25rem;
    }
  }

  .ant-modal-body {
    padding: 24px 30px;
    max-height: calc(100vh - 100px - 80px - 75px);
    overflow-y: auto;
    // &::-webkit-scrollbar {
    //   width: 5px;
    //   height: 8px;
    //   background-color: #eceff6;
    //   // background-color: #aaa;
    // }
    // &::-webkit-scrollbar-thumb {
    //   background: #a5b3cf;
    // }
  }

  .ant-modal-close {
    top: 16px;
    right: 10px;
  }

  &.headerBorder {
    .ant-modal-header {
      border-bottom: 2px solid #d4dbe9;
    }
  }

  &.noHeaderBorder {
    .ant-modal-header {
      border-bottom: none;
      padding-bottom: 20px;
    }
    .ant-modal-body {
      padding: 24px 30px;
      padding-top: 0px;
    }
  }

  &.hasFooter {
    .ant-modal-body {
      padding-bottom: 10px;
    }
    .ant-modal-footer {
      padding: 0px 30px 24px 30px;
      padding-top: 10px;
      border-top: none;
    }
  }
}

.flatFormItemCheckbox {
  display: flex;
  align-items: center;
  // margin-bottom: 0px;
  .ant-checkbox-wrapper {
    & > :nth-child(2) {
      align-self: center;
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-inner {
  width: 32px !important;
  height: 32px !important;
  border-radius: 10px !important;
}
.ant-checkbox-inner::after {
  height: 13.142857px !important;
  width: 7.714286px !important;
  left: 8px !important;
}

.ant-notification {
  z-index: 10000000 !important;
}

// Form builder stuff start
.alwaysbreak {
  page-break-before: always;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}

.form-group {
  label {
    font-family: tradeGothic !important;
    padding-top: 1px !important;
  }
}
.form-header {
  * {
    font-family: "tradeGothic" !important;
  }
}
// Form builder stuff end
.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}
.gap-8 {
  gap: 8px;
}

.white-bc {
  background-color: #fff;
}

.normalFontRadio {
  span {
    font-weight: 400;
    font-family: "tradeGothic";
    font-size: 15px;
  }
  .gap-8 {
    gap: 8px;
  }
}
.mb-16 {
  margin-bottom: 16px;
}

.icon-gray {
  .anticon {
    color: rgb(0 0 0 / 65%);
  }
}

.textEllipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  .ant-modal-title-tradeGothic {
    .ant-modal-title {
      font-family: tradeGothic !important;
    }
  }
}

.checkboxRegular {
  display: flex;
  align-items: center;

  & :nth-child(2) {
    font-weight: 500;
  }
}

.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justifySpaceBetween {
  justify-content: space-between;
}
.gap-12 {
  gap: 12px;
}

.ant-space-item {
  display: flex;
  align-items: center;
}

.standaloneSearch {
  border: 1px solid #d9d9d9 !important;
  border-radius: 6px;
  overflow: hidden;
}

.ant-segmented-group label {
  margin: 0px;
}

@media screen and (max-width: 768px) {
  .ant-layout-header{
    height: 42px !important;
    line-height: 100% !important;
  }
  
}


