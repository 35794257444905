.container {
}

.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 12px;
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardLeftSection {
  display: flex;
  gap: 8px;
}

.cardRightSection {
  display: flex;
  align-items: center;
  gap: 8px;
}

.primaryText {
  padding: 0;
  font-size: 14px;
  color: #486baf;
}

.footer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.noDataText {
  font-family: "tradeGothic";
  font-weight: 500;
}

.cardBrandName {
  font-weight: 700 !important;
  &::first-letter {
    text-transform: capitalize;
  }
}
